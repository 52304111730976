import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import { Desktop } from "common/breakpoints";

export default class HomeLoader extends Component {
	render() {
		return (
			<div className="loader-section-home">
				<div className="search-loading-section-home">
					<Desktop>
						<Skeleton count={1} height={94} />
					</Desktop>
				</div>
				<div className="printers-loading-section-home">
					<Skeleton count={10} height={175} />
				</div>
			</div>
		);
	}
}
