import React from "react";
import PropTypes from "prop-types";
import { Desktop, Touch } from "common/breakpoints";

function ListItem(props) {
	const { building, center, goToPrinterDetailedView, setActivePrinter } = props;
	const listOnClickHandler = e => {
		e.preventDefault();
		e.stopPropagation();
		goToPrinterDetailedView(building.site_id);
	};
	const listOnKeyUpHandler = e => {
		e.preventDefault();
		e.stopPropagation();
		if (e.keyCode === 13) {
			goToPrinterDetailedView(building.site_id);
		}
	};
	return (
		<React.Fragment>
			<Desktop>
				<li id={"printer" + building.site_id}>
					<div
						className="list-section"
						role="button"
						onMouseEnter={e => {
							e.preventDefault();
							e.stopPropagation();
							setActivePrinter(building.site_id, center);
						}}
						onFocus={e => {
							e.preventDefault();
							setActivePrinter(building.site_id, center);
						}}
						onBlur={e => {
							e.preventDefault();
							setActivePrinter(null);
						}}
						onMouseLeave={e => {
							e.preventDefault();
							setActivePrinter(null);
						}}
						onClick={listOnClickHandler}
						tabIndex={0}
						onKeyUp={listOnKeyUpHandler}
					>
						{props.children}
					</div>
				</li>
			</Desktop>
			<Touch>
				<li id={"printer" + building.site_id}>
					<div
						className="list-section"
						role="button"
						onClick={listOnClickHandler}
						tabIndex={0}
						onKeyUp={listOnKeyUpHandler}
					>
						{props.children}
					</div>
				</li>
			</Touch>
		</React.Fragment>
	);
}

ListItem.propTypes = {
	building: PropTypes.shape({
		site_id: PropTypes.string.isRequired
	}),
	center: PropTypes.shape({
		lat: PropTypes.number.isRequired,
		lng: PropTypes.number.isRequired
	}),
	goToPrinterDetailedView: PropTypes.func.isRequired,
	setActivePrinter: PropTypes.func.isRequired,
	children: PropTypes.any
};

export default ListItem;
