import React from "react";
import PropTypes from "prop-types";
import { withTheme, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import FilterImage from "common/images/filter.png";
import Badge from "@material-ui/core/Badge";
import ActiveFilterImage from "common/images/filter-purple.png";
import Filter from "home/filter/quick-filters";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";

const styles = ({ palette }) => ({
	root: {
		padding: "2px 4px",
		display: "block",
		alignItems: "center",
		height: "50px",
		borderRadius: "5px",
		boxShadow: "none",
		backgroundColor: palette.white.main,
		flex: "40px",
	},
	input: {
		marginLeft: 8,
		flex: 1
	},
	iconFilter: {
		float: "right",
		marginLeft: "20px",
		cursor: "pointer",
		padding: "8px",
		"&:focus": {
			backgroundColor: "#D3D3D3",
			outline: "none",
			"-webkit-border-radius": "50%"
		}
	},
	divider: {
		width: 1,
		height: 28,
		margin: 4
	},
	tune: {
		color: palette.black.main,
		width: "26px",
		height: "26px",
		backgroundSize: "contain"
	},
});

function SearchInput(props) {
	const textInput = React.useRef(null);
	const {
		classes,
		numberOfFilters,
		isFilterDrawerOpen,
		filters,
		setFilters,
		toggleFilterDrawer,
		filterPrintersOnlineStatus,
		setFilterPrintersOnlineStatus,
		onChangeHandlerSearch,
		locationPermissionGranted,
		setSortNearestIsActive,
		inputClass
	} = props;
	const _toggleFilterDrawer = () => {
		toggleFilterDrawer();
		textInput.current.focus();
	};

	const updateSorting = (e) => {
		setSortNearestIsActive(e.target.value == 'distance');
	}

	let showFilter = true;
	return (
		<React.Fragment>
			<div className="search-bar-wrap">
				<div
					className="search-content open"
				>
					{(
						<div style={{
							display: "flex",
							alignItems: "center",
						}}>
							<InputBase
								className={inputClass}
									  onChange={onChangeHandlerSearch}
									  placeholder="Search by building or address"
									  autoFocus={true}
									  inputProps={{ tabIndex: 0 }}
							/>
							<SearchIcon style={{ marginTop: "6px" }} />
						</div>
					)}
				</div>
				<Paper className={classes.root} elevation={1}>
					{showFilter && (
						<div
							className={classes.iconFilter}
							aria-label="Filter"
							role="button"
							onClick={toggleFilterDrawer}
							tabIndex={0}
							ref={textInput}
							onKeyUp={e => {
								if (e.keyCode === 13) {
									toggleFilterDrawer();
								}
							}}
							aria-controls="filter-contents"
							aria-expanded={isFilterDrawerOpen}
						>
							<Badge badgeContent={numberOfFilters} color="primary">
								<div
									className={classes.tune}
									style={
										isFilterDrawerOpen
											? { backgroundImage: `url(${ActiveFilterImage})` }
											: { backgroundImage: `url(${FilterImage})` }
									}
								/>
							</Badge>
						</div>
					)}
				</Paper>
			</div>
			<div
				id="filter-contents"
				className={
					isFilterDrawerOpen ? "filter-content open" : "filter-content"
				}
			>
				<Filter
					active={isFilterDrawerOpen}
					filters={filters}
					setFilters={setFilters}
					filterPrintersOnlineStatus={filterPrintersOnlineStatus}
					setFilterPrintersOnlineStatus={setFilterPrintersOnlineStatus}
					toggleFilterDrawer={_toggleFilterDrawer}
					sortNearestIsActive={false}
					setSortNearestIsActive={() => {}}
					locationPermissionGranted={locationPermissionGranted}
				/>
			</div>
		        {locationPermissionGranted ?
				<div className="sort-selection">
					<label htmlFor="setSortBy">Sort by&nbsp;</label>
					<select id="setSortBy"
						onChange={updateSorting}>
						<option value="relevance">Relevance</option>
						<option value="distance">Distance</option>
					</select>
				</div>
				: []}

		</React.Fragment>
	);
}

SearchInput.propTypes = {
	classes: PropTypes.object.isRequired,
	numberOfFilters: PropTypes.number.isRequired,
	isFilterDrawerOpen: PropTypes.bool.isRequired,
	filters: PropTypes.array.isRequired,
	setFilters: PropTypes.func.isRequired,
	toggleFilterDrawer: PropTypes.func.isRequired,
	filterPrintersOnlineStatus: PropTypes.bool.isRequired,
	setFilterPrintersOnlineStatus: PropTypes.func.isRequired,
	onChangeHandlerSearch: PropTypes.func.isRequired,
	inputClass: PropTypes.string.isRequired
};

export default withTheme()(withStyles(styles)(SearchInput));
