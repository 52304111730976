import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
	typography: {
		useNextVariants: true,
		fontFamily: ["Montserrat"].join(","),
		display1: {
			fontSize: "11px",
			fontWeight: 600
		},
		display2: {
			fontSize: "14px",
			fontWeight: 500
		},
		display3: {
			fontSize: "18px",
			fontWeight: 500
		}
	},
	palette: {
		primary: { main: "#6B32FA" },
		secondary: { main: "#6f14fd" },
		black: { main: "#000000" },
		white: { main: "#ffffff" },
		grey: { main: "#707070" }
	},
	shadows: ["0 4px 16px rgba(0, 0, 0, 0.1)"].concat(Array(24).fill("none"))
});

export default theme;
