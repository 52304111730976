const regions = [
	{
		id: 1,
		title: "New York",
		shortTitle: "NY",
		url: "new-york",
		lat: 40.7366686,
		lng: -73.98797569999999
	},
];

export default regions;
