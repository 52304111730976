import React from "react";
import PropTypes from "prop-types";
import "./printers-list.scss";
import history from "history/history";
import { withStyles } from "@material-ui/core/styles";
import SearchbarSectionMobile from "../searchbar-section/searchbar-section-mobile";
import SearchbarSectionDesktop from "../searchbar-section/searchbar-section-desktop";
import List from "./list-component";
import TooltipMobile from "components/map/tooltip-mobile/tooltip-mobile";
import { Desktop, Touch } from "common/breakpoints";

const styles = {
	input: {
		display: "inline-block",
		width: "100%",
		borderBottom: "1px solid #cccccc",
		fontSize: "18px"
	}
};

const PrintersList = props => {
	const { isFilterDrawerOpen } = props;
	const { classes, printers } = props;

	const goToPrinterDetailedView = site_id => {
		history.push("/printer/" + site_id);
	};
	const getCurrentActiveBuildingDetails = () => {
		if (!props.activePrinter.id) return null;
		for (let i = 0; i < printers.length; i++) {
			if (printers[i].site_id === props.activePrinter.id) {
				return printers[i];
			}
		}
	};
	window.addEventListener("resize", () => {
		window.location.reload();
	});
	const currentActiveBuilding = getCurrentActiveBuildingDetails();
	return (
		<React.Fragment>
			<div
				className={
					props.searching ? "content-over-map searching" : "content-over-map"
				}
			>
				<Touch>
					<SearchbarSectionMobile
						currentRegion={props.currentRegion}
						searching={props.searching}
						toggleSearching={props.toggleSearching}
						searchText={props.searchText}
						onChangeHandlerSearch={props.onChangeHandlerSearch}
						isFilterDrawerOpen={isFilterDrawerOpen}
						toggleFilterDrawer={props.toggleFilterDrawer}
						filters={props.filters}
						setFilters={props.setFilters}
					        setSortNearestIsActive={props.setSortNearestIsActive}
						sortNearestIsActive={props.sortNearestIsActive}
						locationPermissionGranted={props.locationPermissionGranted}
					/>
				</Touch>
				<Desktop>
					<SearchbarSectionDesktop
						currentRegion={props.currentRegion}
						toggleFilterDrawer={props.toggleFilterDrawer}
						onChangeHandlerSearch={props.onChangeHandlerSearch}
						isFilterDrawerOpen={isFilterDrawerOpen}
						inputClass={classes.input}
						filters={props.filters}
						setFilters={props.setFilters}
						setFilterPrintersOnlineStatus={props.setFilterPrintersOnlineStatus}
						filterPrintersOnlineStatus={props.filterPrintersOnlineStatus}
						setSortNearestIsActive={props.setSortNearestIsActive}
						locationPermissionGranted={props.locationPermissionGranted}
					/>
				</Desktop>
			</div>
			<React.Fragment>
				<Touch>
					{!currentActiveBuilding && (
						<List
							isPrinterListCollapsed={props.isPrinterListCollapsed}
							currentRegion={props.currentRegion}
							toggleFilterDrawer={props.toggleFilterDrawer}
							handleClick={props.handleClick}
							searching={props.searching}
							filters={props.filters}
							setFilterPrintersOnlineStatus={
								props.setFilterPrintersOnlineStatus
							}
							filterPrintersOnlineStatus={props.filterPrintersOnlineStatus}
							setActivePrinter={props.setActivePrinter}
							goToPrinterDetailedView={goToPrinterDetailedView}
							locationPermissionGranted={props.locationPermissionGranted}
							printers={printers}
							sortNearestIsActive={props.sortNearestIsActive}
						/>
					)}
					{currentActiveBuilding && (
						<TooltipMobile
							building={currentActiveBuilding}
							setActivePrinter={props.setActivePrinter}
							locationPermissionGranted={props.locationPermissionGranted}
						/>
					)}
				</Touch>
				<Desktop>
					<List
						isPrinterListCollapsed={props.isPrinterListCollapsed}
						currentRegion={props.currentRegion}
						toggleFilterDrawer={props.toggleFilterDrawer}
						handleClick={props.handleClick}
						searching={props.searching}
						filters={props.filters}
						setFilterPrintersOnlineStatus={props.setFilterPrintersOnlineStatus}
						filterPrintersOnlineStatus={props.filterPrintersOnlineStatus}
						setActivePrinter={props.setActivePrinter}
						goToPrinterDetailedView={goToPrinterDetailedView}
						locationPermissionGranted={props.locationPermissionGranted}
						printers={printers}
						sortNearestIsActive={props.sortNearestIsActive}
					/>
				</Desktop>
			</React.Fragment>
		</React.Fragment>
	);
};

PrintersList.propTypes = {
	isPrinterListCollapsed: PropTypes.bool.isRequired,
	searching: PropTypes.bool.isRequired,
	toggleSearching: PropTypes.func.isRequired,
	searchText: PropTypes.string.isRequired,
	printers: PropTypes.array.isRequired,
	handleClick: PropTypes.func.isRequired,
	openPrinterList: PropTypes.func.isRequired,
	locationPermissionGranted: PropTypes.bool.isRequired,
	setActivePrinter: PropTypes.func.isRequired,
	activePrinter: PropTypes.object,
	currentRegion: PropTypes.object.isRequired,
	filterPrintersOnlineStatus: PropTypes.bool.isRequired,
	setFilterPrintersOnlineStatus: PropTypes.func.isRequired,
	filters: PropTypes.array.isRequired,
	setFilters: PropTypes.func.isRequired,
	onChangeHandlerSearch: PropTypes.func.isRequired,
	isFilterDrawerOpen: PropTypes.bool.isRequired,
	sortNearestIsActive: PropTypes.bool.isRequired,
	toggleFilterDrawer: PropTypes.func.isRequired,
	setSortNearestIsActive: PropTypes.func.isRequired,
	locationPermissionGranted: PropTypes.bool.isRequired,
	classes: PropTypes.shape({
		input: PropTypes.string.isRequired
	})
};

export default withStyles(styles)(PrintersList);
