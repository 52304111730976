import React from "react";
import DesktopSearchInput from "components/search-input/desktop/desktop-search-input";
import PropTypes from "prop-types";

export default function SearchbarSection(props) {
	const {
		currentRegion,
		isFilterDrawerOpen,
		toggleFilterDrawer,
		filters,
		setFilters,
		filterPrintersOnlineStatus,
		setFilterPrintersOnlineStatus,
		onChangeHandlerSearch,
		setSortNearestIsActive,
		locationPermissionGranted,
		inputClass
	} = props;
	return (
		<React.Fragment>
			<DesktopSearchInput
				currentRegion={currentRegion}
				isFilterDrawerOpen={isFilterDrawerOpen}
				toggleFilterDrawer={toggleFilterDrawer}
				numberOfFilters={filters.length}
				filters={filters}
				setFilters={setFilters}
				filterPrintersOnlineStatus={filterPrintersOnlineStatus}
				setFilterPrintersOnlineStatus={setFilterPrintersOnlineStatus}
				inputClass={inputClass}
				onChangeHandlerSearch={onChangeHandlerSearch}
		                setSortNearestIsActive={setSortNearestIsActive}
				locationPermissionGranted={locationPermissionGranted}
			/>
		</React.Fragment>
	);
}

SearchbarSection.propTypes = {
	currentRegion: PropTypes.object.isRequired,
	isFilterDrawerOpen: PropTypes.bool.isRequired,
	toggleFilterDrawer: PropTypes.func.isRequired,
	filters: PropTypes.array.isRequired,
	setFilters: PropTypes.func.isRequired,
	filterPrintersOnlineStatus: PropTypes.bool.isRequired,
	setFilterPrintersOnlineStatus: PropTypes.func.isRequired,
	onChangeHandlerSearch: PropTypes.func.isRequired,
	inputClass: PropTypes.string.isRequired
};
