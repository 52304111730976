import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { withTheme, withStyles } from "@material-ui/core/styles";
import FilterImage from "common/images/filter-purple.png";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CloseImg from "common/images/close-purple.png";
import { Desktop, Touch } from "common/breakpoints";

const styles = ({ typography, palette }) => ({
	root: {
		padding: "30px 25px"
	},
	tune: {
		width: "26px",
		height: "26px",
		backgroundSize: "contain",
		display: "inline-block",
		verticalAlign: "middle"
	},
	close: {
		width: "26px",
		height: "26px",
		backgroundSize: "contain"
	},
	iconButton: {
		float: "right",
		verticalAlign: "middle",
		padding: 0
	},
	headerSection: {
		color: palette.primary.main
	},
	title: {
		display: "inline-block",
		verticalAlign: "middle",
		marginLeft: "20px",
		fontWeight: "bold",
		fontSize: typography.display3.fontSize
	},
	filters: {
		display: "inline-block",
		verticalAlign: "middle",
		border: `2px solid ${palette.primary.main}`,
		padding: "5px",
		paddingLeft: "12px",
		paddingRight: "12px",
		marginRight: "10px",
		marginBottom: "17px",
		color: palette.primary.main,
		cursor: "pointer",
		fontSize: typography.display2.fontSize,
		borderRadius: "2px"
	},
	filterSelected: {
		display: "inline-block",
		verticalAlign: "middle",
		border: `2px solid ${palette.primary.main}`,
		padding: "5px",
		paddingLeft: "12px",
		paddingRight: "12px",
		marginRight: "10px",
		marginBottom: "17px",
		color: palette.white.main,
		background: palette.primary.main,
		fontSize: typography.display2.fontSize,
		cursor: "pointer",
		borderRadius: "2px"
	},
	filtersSection: {
		marginTop: "30px"
	},
	button: {
		float: "right",
		color: palette.primary.main,
		fontWeight: "bold",
		fontSize: typography.display3.fontSize,
		cursor: "pointer"
	},
	checkboxLabel: {
		color: palette.primary.main
	}
});
function Filter(props) {
	const { classes, toggleFilterDrawer, setFilterPrintersOnlineStatus } = props;
	const inputRef = useRef(null);
	let initialFilters = [
		{ name: "Brooklyn", searchName: "brooklyn", selected: false },
		{ name: "Manhattan", searchName: "new york", selected: false }
	];
	props.filters.forEach(propFilters => {
		initialFilters.forEach(filters => {
			if (filters.name === propFilters) filters.selected = true;
		});
	});
	const [filters, setFilters] = React.useState(initialFilters);

	const [formSortByDistance, setFormSortByDistance] = React.useState(props.sortNearestIsActive);

	const applyFilter = name => {
		let localFilter = filters.slice();
		localFilter = localFilter.map(filter => {
			if (filter.name === name) filter.selected = !filter.selected;
			return filter;
		});
		setFilters(localFilter);
	};
	const submitFilters = () => {
		props.setSortNearestIsActive(formSortByDistance);

		const localFilter = filters.slice();
		let applyFilters = [];
		localFilter.forEach(filter => {
			if (filter.selected) {
				applyFilters.push(filter.searchName);
			}
		});
		props.setFilters(applyFilters);
		toggleFilterDrawer();
	};

	useEffect(() => {
		if (props.active) inputRef.current.focus();
	}, [props.active]);

	if (!props.active) return null;
	return (
		<div className={classes.root} ref={inputRef}>
			<fieldset>
				<div className={classes.headerSection}>
					<div
						className={classes.tune}
						style={{ backgroundImage: `url(${FilterImage})` }}
					/>
					<legend>Filters</legend>
					<div className={classes.title}>Filters</div>
					<div
						className={classes.iconButton}
						aria-label="Close filter"
						aria-controls="filter-contents"
						role="button"
						onKeyUp={e => {
							if (e.keyCode === 13) {
								toggleFilterDrawer();
							}
						}}
						onClick={() => toggleFilterDrawer()}
						tabIndex={props.active ? 0 : -1}
					>
						<div
							className={classes.close}
							style={{ backgroundImage: `url(${CloseImg})` }}
						></div>
					</div>
				</div>
				<ul className={classes.filtersSection}>
					{filters.map((filter, index) => {
						return (
							<li
								key={index}
								className={
									filter.selected ? classes.filterSelected : classes.filters
								}
								role="checkbox"
								aria-checked={filter.selected}
								aria-labelledby={filter.name}
								tabIndex={props.active ? 0 : -1}
								onClick={() => applyFilter(filter.name)}
								onKeyUp={e => {
									if (e.keyCode === 13) {
										applyFilter(filter.name);
									}
								}}
							>
								{filter.name}
							</li>
						);
					})}
				</ul>
				{props.locationPermissionGranted ? (
				<Touch>
					<FormControlLabel
						control={
							<Checkbox
								color="primary"
								       onChange={() => {
									       setFormSortByDistance(!formSortByDistance);
								       }}
								       onKeyUp={e => {
									       setFormSortByDistance(!formSortByDistance);
								       }}
								       checked={formSortByDistance}
							/>
						}
						className={classes.checkboxLabel}
						label={
							<span className={classes.availablePrinters}>
									Show printers nearest to me first
							</span>
						}
					/>
				</Touch>) : []}

			</fieldset>
			<Desktop>
				<FormControlLabel
					control={
						<Checkbox
							color="primary"
							checked={props.filterPrintersOnlineStatus}
							onChange={() => {
								setFilterPrintersOnlineStatus(
									!props.filterPrintersOnlineStatus
								);
							}}
							onKeyUp={e => {
								if (e.keyCode === 13) {
									setFilterPrintersOnlineStatus(
										!props.filterPrintersOnlineStatus
									);
								}
							}}
						/>
					}
					className={classes.checkboxLabel}
					label={
						<span className={classes.availablePrinters}>
							Available Printers Only
						</span>
					}
				/>
			</Desktop>

			<div
				className={classes.button}
				tabIndex={props.active ? 0 : -1}
				onClick={() => submitFilters()}
				onKeyUp={e => {
					if (e.keyCode === 13) {
						submitFilters();
					}
				}}
				aria-label="apply filters"
				role="button"
				aria-controls="filter-contents"
			>
				Apply
			</div>
		</div>
	);
}

Filter.propTypes = {
	toggleFilterDrawer: PropTypes.func.isRequired,
	filters: PropTypes.array.isRequired,
	active: PropTypes.bool.isRequired,
	setFilters: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	filterPrintersOnlineStatus: PropTypes.bool,
	setFilterPrintersOnlineStatus: PropTypes.func,
	sortNearestIsActive: PropTypes.bool,
	locationPermissionGranted: PropTypes.bool,
	setSortNearestIsActive: PropTypes.func
};

export default withTheme()(withStyles(styles)(Filter));
