import React from "react";
import GoogleMapReact from "google-map-react";
import mapConfig from "./map-config.js";
import "./map.scss";
import Markers from "./markers";
import helper from "common/helper.js";
import PropTypes from "prop-types";

const Map = props => {
	const {
		buildings,
		center,
		zoom,
		locationPermissionGranted,
		userLocation,
		activePrinter,
		setActivePrinter,
		detailsView,
		updateCenterFromMaps
	} = props;
	const _onChange = ({ center, zoom }) => {
		updateCenterFromMaps(center, zoom);
	};
	const mapOptions = () => {
		return {
			styles: [
				{
					featureType: "poi",
					stylers: [{ visibility: "off" }]
				},
				{
					featureType: "transit",
					elementType: "labels.icon",
					stylers: [{ visibility: "off" }]
				}
			]
		};
	};
	const onMapClick = e => {
		//close the tool tip (i.e., active printer) when clicked or touched on the map
		setActivePrinter(null);
	};
	const isOnline = printers => {
		for (let i = 0; i < printers.length; i++) {
			if (printers[i].IsInService) {
				return true;
			}
		}
		return false;
	};
	return (
		<div className="maps-wrap">
			<GoogleMapReact
				onChange={_onChange}
				bootstrapURLKeys={{ key: mapConfig.key }}
				initialCenter={mapConfig.center}
				defaultZoom={mapConfig.zoom}
				center={center && center.lat && center.lng ? center : mapConfig.center}
				zoom={zoom || mapConfig.zoom}
				options={mapOptions}
				onClick={onMapClick}
			>
				{buildings &&
					buildings.length > 0 &&
					buildings.map((building, index) => {
						let activePrinterisSet = false;
						let printerType = helper.getPrintersByType(building.printers);
						if (activePrinter && activePrinter.id === building.site_id)
							activePrinterisSet = true;

						return (
							<Markers
								key={index}
								lat={building.lat}
								lng={building.lng}
								activePrinter={activePrinterisSet}
								text={building.site}
								site_id={building.site_id}
								setActivePrinter={setActivePrinter}
								printerType={printerType}
								detailsView={detailsView}
								index={building.site_id}
								onlineStatus={isOnline(building.printers)}
							/>
						);
					})}
				{locationPermissionGranted && (
					<Markers
						myLocation={true}
						lat={userLocation.lat}
						lng={userLocation.lng}
					/>
				)}
			</GoogleMapReact>
		</div>
	);
};

Map.propTypes = {
	buildings: PropTypes.array.isRequired,
	center: PropTypes.object.isRequired,
	zoom: PropTypes.number,
	locationPermissionGranted: PropTypes.bool.isRequired,
	userLocation: PropTypes.object,
	activePrinter: PropTypes.object,
	setActivePrinter: PropTypes.func.isRequired,
	detailsView: PropTypes.bool,
	updateCenterFromMaps: PropTypes.func
};

export default Map;
