import React from "react";
import UpImage from "common/images/up.svg";
import IconButton from "@material-ui/core/IconButton";
import FilterImage from "common/images/filter.png";
import { withStyles } from "@material-ui/core/styles";
import OnlineFilter from "./online-filter";
import Badge from "@material-ui/core/Badge";
import PropTypes from "prop-types";

const styles = {
	iconButton: {
		float: "right",
		marginTop: "33px"
	},
	tune: {
		width: "25px",
		height: "25px",
		backgroundSize: "contain"
	}
};

function SwipeFilter(props) {
	const {
		classes,
		currentRegion,
		numberOfFilters,
		searching,
		isPrinterListCollapsed,
		setFilterPrintersOnlineStatus,
		filterPrintersOnlineStatus,
		toggleFilterDrawer,
		handleClick
	} = props;
	let showFilter = false;
	if (currentRegion && currentRegion.title === "New York") showFilter = true;
	return (
		<div className="swipe-section-wrap">
			<div className="swipe-section">
				{!searching && (
					<div
						className="swipe-image"
						role="button"
						onTouchStart={e => {
							e.preventDefault();
							e.stopPropagation();
							handleClick(e);
						}}
						onTouchEnd={e => {
							e.preventDefault();
							e.stopPropagation();
						}}
						onClick={e => {
							e.preventDefault();
							e.stopPropagation();
							handleClick(e);
						}}
						onKeyUp={e => {
							e.preventDefault();
							e.stopPropagation();
							if (e.keyCode === 13) {
								props.handleClick(e);
							}
						}}
						tabIndex={0}
					>
						<div
							className={isPrinterListCollapsed ? "swipe-up" : "swipe-down"}
							style={{ backgroundImage: `url(${UpImage})` }}
						/>
					</div>
				)}

				<OnlineFilter
					setFilterPrintersOnlineStatus={setFilterPrintersOnlineStatus}
					filterPrintersOnlineStatus={filterPrintersOnlineStatus}
				/>
				{showFilter && (
					<IconButton
						color="primary"
						className={classes.iconButton}
						aria-label="Filter"
						onClick={e => {
							e.stopPropagation();
							toggleFilterDrawer();
						}}
						tabIndex={0}
					>
						<Badge badgeContent={numberOfFilters} color="primary">
							<div
								className={classes.tune}
								style={{ backgroundImage: `url(${FilterImage})` }}
							/>
						</Badge>
					</IconButton>
				)}
			</div>
		</div>
	);
}

SwipeFilter.propTypes = {
	isPrinterListCollapsed: PropTypes.bool.isRequired,
	classes: PropTypes.object.isRequired,
	currentRegion: PropTypes.object.isRequired,
	numberOfFilters: PropTypes.number.isRequired,
	searching: PropTypes.bool.isRequired,
	setFilterPrintersOnlineStatus: PropTypes.func.isRequired,
	filterPrintersOnlineStatus: PropTypes.bool.isRequired,
	toggleFilterDrawer: PropTypes.func.isRequired,
	handleClick: PropTypes.func.isRequired
};

export default withStyles(styles)(SwipeFilter);
