import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import placeholderImg from "common/images/Printer-Icon.png";
import helper from "common/helper.js";
import SingleBuilding from "home/printers-list/single-building/single-building";
import CloseImg from "common/images/close-purple.png";
import history from "history/history";

const styles = ({ typography, palette }) => ({
	root: {
		position: "absolute",
		bottom: "10px",
		width: "100%",
		height: "197px",
		zIndex: "10",
		padding: "0px 10px 0px 10px"
	},
	content: {
		background: "white",
		height: "100%",
		width: "100%",
		borderRadius: "5px",
		padding: "23px 20px"
	},
	actionsWrap: {
		width: "100%",
		padding: "15px 0 15px 0",
		textAlign: "center",
		zIndex: "10",
		display: "flex",
		justifyContent: "space-between",
		"-webkit-flex-flow": "row wrap"
	},
	button: {
		height: "25px",
		width: "146px",
		display: "inline-block",
		lineHeight: "25px",
		textAlign: "center",
		fontSize: typography.display1.fontSize,
		cursor: "pointer",
		borderRadius: "2px",
		fontWeight: typography.display1.fontWeight
	},
	directions: {
		backgroundColor: "white",
		color: palette.primary.main,
		border: `2px solid ${palette.primary.main}`,
		fontWeight: 400
	},
	printViaEmail: {
		backgroundColor: palette.primary.main,
		color: "white",
		fontWeight: 600
	},
	closeButton: {
		width: "25px",
		height: "25px",
		backgroundSize: "contain",
		backgroundRepeat: "no-repeat",
		position: "absolute",
		right: "15px",
		top: "10px"
	}
});

const TooltipMobile = props => {
	const {
		classes,
		building,
		setActivePrinter,
		locationPermissionGranted
	} = props;
	let printerImg = placeholderImg;
	let printerType = helper.getPrintersByType(building.printers);
	if (building.ImageUrl && building.ImageUrl !== "")
		printerImg = building.ImageUrl;

	const showDirections = () => {
		const url =
			"https://www.google.com/maps/dir/?api=1&destination=" +
			building.lat +
			"," +
			building.lng;
		window.open(url, "_blank");
	};
	const closeTooltip = e => {
		e.stopPropagation();
		e.preventDefault();
		setActivePrinter();
	};

	const goToDetails = e => {
		e.stopPropagation();
		e.preventDefault();
		history.push("/printer/" + building.site_id);
	};
	return (
		<div className={classes.root}>
			<div
				className={classes.closeButton}
				onClick={closeTooltip}
				style={{ backgroundImage: `url(${CloseImg})` }}
			></div>
			<div className={classes.content}>
				<SingleBuilding
					building={building}
					printerType={printerType}
					printerImg={printerImg}
					locationPermissionGranted={locationPermissionGranted}
					showPrinterImage={false}
				/>
				<div className={classes.actionsWrap}>
					<div
						className={`${classes.button} ${classes.directions}`}
						tabIndex={0}
						role="button"
						onClick={showDirections}
						onKeyUp={e => {
							if (e.keyCode === 13) {
								showDirections();
							}
						}}
					>
						Directions
					</div>
					<div
						className={`${classes.button} ${classes.printViaEmail}`}
						tabIndex={0}
						role="button"
						onClick={e => goToDetails(e)}
						onKeyUp={e => {
							if (e.keyCode === 13) {
								goToDetails(e);
							}
						}}
					>
						See Details
					</div>
				</div>
			</div>
		</div>
	);
};

TooltipMobile.propTypes = {
	building: PropTypes.shape({
		FormattedAddress: PropTypes.string.isRequired,
		ImageUrl: PropTypes.string.isRequired,
		Region: PropTypes.string.isRequired,
		building: PropTypes.string.isRequired,
		distance: PropTypes.number.isRequired,
		lat: PropTypes.number.isRequired,
		lng: PropTypes.number.isRequired,
		place_id: PropTypes.string.isRequired,
		printers: PropTypes.array.isRequired,
		site: PropTypes.string.isRequired,
		site_id: PropTypes.string.isRequired
	}),
	setActivePrinter: PropTypes.func.isRequired,
	classes: PropTypes.object,
	locationPermissionGranted: PropTypes.bool.isRequired
};

export default withStyles(styles)(TooltipMobile);
