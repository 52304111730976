import React, { useState } from "react";
import PropTypes from "prop-types";
import { withTheme, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const styles = ({ palette }) => ({
	filterIconDown: {
		position: "relative",
		width: "25px",
		height: "30px",
		backgroundSize: "contain",
		left: "8px",
		top: "0px",
		color: "black",
		transition: "all 200ms ease"
	},
	filterIconUp: {
		transform: "rotate(180deg)",
		color: palette.primary.main
	},
	selectButton: {
		fontSize: "22px",
		minWidth: "165px",
		fontWeight: "bold",
		backgroundColor: "white",
		boxShadow: "none",
		textTransform: "Capitalize",
		borderBottom: "1px solid #333333",
		borderRadius: "0"
	},
	dropDownOpen: {
		borderBottom: `2px solid ${palette.primary.main}`
	},
	menuRoot: {
		top: "2px"
	},
	menu: {
		paddingTop: "0",
		paddingBottom: "0"
	},
	selectedMenuItem: {
		color: palette.primary.main
	},
	iconButton: {
		float: "right",
		verticalAlign: "middle",
		padding: 0
	},
	close: {
		width: "26px",
		height: "26px",
		backgroundSize: "contain"
	}
});

function Dropdown(props) {
	const { classes } = props;
	const [anchorEl, setAnchorEl] = useState(null);
	const titleFontSize = {
		fontSize: props.fontSize.title
	};
	const menuListprops = {
		paddingTop: 0,
		paddingBottom: 0,
		minWidth: props.listWidth.minWidth || "165px",
		borderRadius: 0
	};

	const paperProps = {
		borderRadius: 0
	};
	const handleChange = e => {
		e.stopPropagation();
		handleClose();
		props.handleChange(e.target.value);
	};
	const handleClick = event => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	return (
		<React.Fragment>
			<Button
				aria-controls={props.id}
				aria-haspopup="true"
				onClick={handleClick}
				color="primary"
				className={
					anchorEl
						? `${classes.selectButton} ${classes.dropDownOpen}`
						: `${classes.selectButton}`
				}
				aria-expanded={Boolean(anchorEl)}
				style={titleFontSize}
			>
				{props.activeName}
				<ArrowDropDownIcon
					className={
						anchorEl
							? `${classes.filterIconDown} ${classes.filterIconUp}`
							: `${classes.filterIconDown}`
					}
				/>
			</Button>
			<Menu
				id={props.id}
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				PaperProps={{
					style: paperProps,
					elevation: 0
				}}
				MenuListProps={{
					style: menuListprops
				}}
				className={classes.menuRoot}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				transformOrigin={{ vertical: "top", horizontal: "center" }}
			>
				{props.list.map(current => {
					return (
						<MenuItem
							key={current.id}
							onClick={handleChange}
							value={current.id}
							selected={current.id === props.activeId}
							className={
								props.activeId === current.id
									? classes.selectedMenuItem
									: classes.menuItem
							}
						>
							{current.title}
						</MenuItem>
					);
				})}
			</Menu>
		</React.Fragment>
	);
}

Dropdown.propTypes = {
	classes: PropTypes.object.isRequired,
	fontSize: PropTypes.object,
	listWidth: PropTypes.object,
	handleChange: PropTypes.func.isRequired,
	id: PropTypes.string.isRequired,
	activeName: PropTypes.string.isRequired,
	list: PropTypes.array.isRequired,
	activeId: PropTypes.number.isRequired
};

export default withTheme()(withStyles(styles)(Dropdown));
