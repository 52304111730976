import React from "react";
import PropTypes from "prop-types";
import oval from "common/images/oval.svg";
import colorPrinter from "common/images/color.svg";

function TooltipDesktop(props) {
	const { goToDetails, printerType, text, site_id } = props;
	return (
		<div
			className="tool-tip"
			role="button"
			tabIndex={0}
			onKeyUp={e => {
				if (e.keyCode === 13) {
					goToDetails(site_id, e);
				}
			}}
			onClick={e => goToDetails(site_id, e)}
		>
			<div className="name">{text}</div>
			<div className="details-content-section">
				<div className="available-printers">
					<div className="img" style={{ backgroundImage: `url(${oval})` }} />
				</div>
				<div className="available-printers">
					{printerType.colorNotSupported}
				</div>
			</div>
			<div className="details-content-section">
				<div className="available-printers">
					<div
						className="img"
						style={{ backgroundImage: `url(${colorPrinter})` }}
					/>
				</div>
				<div className="available-printers">{printerType.colorSupported}</div>
			</div>
			<div className="triangle"></div>
		</div>
	);
}

TooltipDesktop.propTypes = {
	printerType: PropTypes.shape({
		colorNotSupported: PropTypes.string.isRequired,
		colorSupported: PropTypes.string.isRequired
	}),
	goToDetails: PropTypes.func.isRequired,
	text: PropTypes.string.isRequired,
	site_id: PropTypes.string.isRequired
};

export default TooltipDesktop;
