import React from "react";
import PropTypes from "prop-types";
import { withTheme, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import LeftIcon from "common/images/details-back.svg";
import IconButton from "@material-ui/core/IconButton";

const styles = ({ palette }) => ({
	root: {
		padding: "2px 4px",
		display: "flex",
		alignItems: "center",
		width: "100%",
		height: "50px",
		borderRadius: "5px",
		boxShadow: "none",
		backgroundColor: palette.white.main
	},
	input: {
		marginLeft: 8,
		flex: 1
	},
	iconButton: {
		padding: 10
	},
	back: {
		width: "30px",
		height: "30px",
		backgroundSize: "contain",
		backgroundRepeat: "no-repeat"
	},
	divider: {
		height: "100%",
		width: "1px"
	}
});

function SearchInput(props) {
	const {
		classes,
		toggleSearching,
		searching,
		onChangeHandlerSearch,
		searchText,
	} = props;

	const focusHandler = e => {
		toggleSearching(true);
	};
	return (
		<Paper className={classes.root} elevation={1}>
			<span className={classes.iconButton} aria-label="Search">
				{!searching && <SearchIcon />}
				{searching && (
					<IconButton
						aria-label="back to list"
						onClick={() => toggleSearching(false)}
						tabIndex={0}
					>
						<div
							className={classes.back}
							style={{ backgroundImage: `url(${LeftIcon})` }}
						/>
					</IconButton>
				)}
			</span>
			<InputBase
				onChange={onChangeHandlerSearch}
				value={searchText}
				className={classes.input}
				placeholder="Search by building or address"
				inputProps={{ tabIndex: 0 }}
				onFocus={focusHandler}
			/>
		</Paper>
	);
}

SearchInput.propTypes = {
	classes: PropTypes.object.isRequired,
	toggleSearching: PropTypes.func.isRequired,
	searching: PropTypes.bool.isRequired,
	onChangeHandlerSearch: PropTypes.func.isRequired,
	searchText: PropTypes.string.isRequired,
};

export default withTheme()(withStyles(styles)(SearchInput));
