import React from "react";
import PropTypes from "prop-types";
import "./home.scss";
import Maps from "../components/map/map";
import PrintersList from "./printers-list/printers-list";
import HomeLoader from "./home-loader";
import MapConfig from "components/map/map-config";
import config from "config/config.js";
import regions from "./printer-regions-config";
import HelpComponent from "components/help-section/help";
import Modal from "@material-ui/core/Modal";
import mapConfig from "../components/map/map-config";
import history from "history/history";
import myLocationIcon from "common/images/current-location.png";
import Slide from "@material-ui/core/Slide";
import { Touch, Desktop } from "common/breakpoints";
import CarouselComponent from "components/help-section/carousel/carousel";

class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isPrinterListCollapsed: true, // mobile only
			center: {},
			userLocation: null,
			locationPermissionReturned: false,
			locationPermissionGranted: false,
			isHelpSectionOpen: false,
			buildings: null,
			activePrinter: { id: null },
			filters: [],
			searchText: "",
			filterPrintersOnlineStatus: true,
			searching: false, // mobile only
			isFilterDrawerOpen: false,
			resultsSortBy: "relevance",
		};
	}
	componentDidMount() {
		//safari and chrome in mobile UI fix --vh used in home.scss
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty("--vh", `${vh}px`);

		this.watchForLocation();

		document.body.style.overflow = "hidden";

		// if there is no region in the URL, navigate him to New York region by default
		if (
			!this.props.match.params.region ||
			!this.getRegion(this.props.match.params.region)
		)
			history.push("/" + regions[0].url);

		this.getPrinters(this.props);
	}
	UNSAFE_componentWillReceiveProps(newProps) {
		this.getPrinters(newProps);
	}
	watchForLocation() {
		if (this.locationPermissionReturned) {
			return;
		}

		if (typeof window.psUserCurrentLocation === 'undefined') {
			setTimeout(() => {
				this.watchForLocation();
			}, 1000)
		} else {
			if (window.psUserCurrentLocation) {
				const userLocation = {
					lat: window.psUserCurrentLocation.coords.latitude,
					lng: window.psUserCurrentLocation.coords.longitude
				};

				this.setState({
					locationPermissionReturned: true,
					userLocation,
					locationPermissionGranted: true,
					zoom: MapConfig.zoom,
				});
			} else {
				this.setState({
					locationPermissionReturned: true,
					userLocation: null,
					locationPermissionGranted: false,
					zoom: MapConfig.zoom,
				});
			}
		}
	}
	componentWillUnmount() {
		document.body.style.overflow = "scroll";
	}
	getRegion = url => {
		const regionFromUrl = regions.find(region => region.url === url);
		return regionFromUrl;
	};
	getPrinters = (props) => {
		const regionNameFromUrl = this.getRegion(props.match.params.region);

		let params = new URLSearchParams();
		if (regionNameFromUrl) {
			params.append("region", regionNameFromUrl.title);
		}

		if (this.state.resultsSortBy === "distance" && this.state.userLocation) {
			params.append("lat", this.state.userLocation.lat);
			params.append("lon", this.state.userLocation.lng);
		}

		// let region = regionNameFromUrl ? "?region=" + regionNameFromUrl.title : "";
		const url = config.url + "?" + params.toString();
		fetch(url)
			.then(resp => resp.json())
			.then(data => {
				let center = {
					lat: regionNameFromUrl ? regionNameFromUrl.lat : mapConfig.center.lat,
					lng: regionNameFromUrl ? regionNameFromUrl.lng : mapConfig.center.lng
				};
				this.setState({
					buildings: data.payload,
					center,
					filters: [],
					isFilterDrawerOpen: false,
				});
			})
			.catch(error => {
				console.log(error);
			});
	};
	setActivePrinter = (id, center) => {
		const activePrinter = {
			id
		};
		if (center) {
			this.setState({
				activePrinter,
				center,
				zoom: 15
			});
		} else {
			this.setState({
				activePrinter
			});
		}
	};
	showPosition = position => {
		//show position only when user clicks on the button
		const userLocation = {
			lat: position.coords.latitude,
			lng: position.coords.longitude
		};
		this.setState({
			userLocation,
			zoom: MapConfig.zoom,
			locationPermissionGranted: true
		});
		this.getPrinters(this.props);
	};
	centerToUserLocation = () => {
		this.setState({
			center: this.state.userLocation
		});
	};
	updateCenterFromMaps = (center, zoom) => {
		this.setState({
			center,
			zoom
		});
	};
	openPrinterList = () => {
		if (!this.state.isPrinterListCollapsed) {
			this.setState({
				isPrinterListCollapsed: true
			});
		}
	};
	handleClick = e => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		this.setState({
			isPrinterListCollapsed: !this.state.isPrinterListCollapsed
		});
	};
	openHelpSection = e => {
		e.preventDefault();
		this.setState({
			isHelpSectionOpen: true
		});
	};
	closeHelpSection = e => {
		e.preventDefault();
		this.setState({
			isHelpSectionOpen: false
		});
	};
	find = (items, text) => {
		text = text.split(" ");
		return items.filter(function(item) {
			return text.every(function(el) {
				return (
					(item.site && item.site.toLowerCase().indexOf(el.toLowerCase()) > -1) ||
					(item.building && item.building.toLowerCase().indexOf(el.toLowerCase()) > -1)
				);
			});
		});
	};
	setFilters = filters => {
		this.setState({
			filters
		});
	};
	applyFilter = (items, filters) => {
		let filtersApplied = {},
			finalFiltersApplied = [];
		if (filters && filters.length > 0) {
			filters.forEach(filter => {
				let text = filter.split(" ");
				let temp = items.filter(function(item) {
					return text.every(function(el) {
						return (
							item.FormattedAddress &&
							item.FormattedAddress.toLowerCase().indexOf(el) > -1
						);
					});
				});
				temp.forEach(item => {
					filtersApplied[item.site_id] = item;
				});
			});
			Object.keys(filtersApplied).forEach(key => {
				finalFiltersApplied.push(filtersApplied[key]);
			});
			return finalFiltersApplied;
		}
		return items;
	};

	setFilterPrintersOnlineStatus = status => {
		this.setState({
			filterPrintersOnlineStatus: status
		});
	};
	findOnline = (items, status) => {
		if (status) {
			return items.filter(item => {
				return this.isOnline(item.printers);
			});
		} else {
			return items;
		}
	};
	isOnline = printers => {
		for (let i = 0; i < printers.length; i++) {
			if (printers[i].IsInService) {
				return true;
			}
		}
		return false;
	};
	onChangeHandlerSearch = e => {
		e.preventDefault();
		this.setState({
			searchText: e.target.value
		});
		if (e.target.value) {
			this.openPrinterList();
		}
	};
	toggleSearching = searching => {
		if (searching) {
			this.setState({
				searching
			});
			this.setActivePrinter(null);
		} else {
			this.setState({
				searching,
				searchText: "",
				isPrinterListCollapsed: false
			});
		}
	};
	toggleFilterDrawer = () => {
		this.setState({
			isFilterDrawerOpen: !this.state.isFilterDrawerOpen,
		});
	};
	setSortNearestIsActive = (value) => {
		this.setState({resultsSortBy: value ? 'distance' : 'relevance'},
			      () => { this.getPrinters(this.props); });
	};
	render() {
		//TODO: handle loadings
		const { buildings: unfilteredBuilding } = this.state;
		if (!unfilteredBuilding) {
			// show loader when user is yet to grant location permissions
			return <HomeLoader />;
		}
		const filteredBuildings = this.applyFilter(
			unfilteredBuilding,
			this.state.filters
		);
		const searchedBuildingbuildings = this.find(
			filteredBuildings,
			this.state.searchText
		);
		const buildings = this.findOnline(
			searchedBuildingbuildings,
			this.state.filterPrintersOnlineStatus
		);
		return (
			<div className="home-wrap">
				<PrintersList
					isPrinterListCollapsed={this.state.isPrinterListCollapsed}
					searching={this.state.searching}
					toggleSearching={this.toggleSearching}
					searchText={this.state.searchText}
					printers={buildings}
					handleClick={this.handleClick}
					openPrinterList={this.openPrinterList}
					locationPermissionGranted={this.state.locationPermissionGranted}
					setActivePrinter={this.setActivePrinter}
					activePrinter={this.state.activePrinter}
					currentRegion={this.getRegion(this.props.match.params.region)}
					filterPrintersOnlineStatus={this.state.filterPrintersOnlineStatus}
					setFilterPrintersOnlineStatus={this.setFilterPrintersOnlineStatus}
					filters={this.state.filters}
					setFilters={this.setFilters}
					onChangeHandlerSearch={this.onChangeHandlerSearch}
					isFilterDrawerOpen={this.state.isFilterDrawerOpen}
					toggleFilterDrawer={this.toggleFilterDrawer}
					setSortNearestIsActive={this.setSortNearestIsActive}
					sortNearestIsActive={this.state.resultsSortBy === 'distance'}

				/>
				<div
					className="help-icon"
					onClick={this.openHelpSection}
					onKeyUp={e => {
						if (e.keyCode === 13) {
							this.openHelpSection(e);
						}
					}}
					tabIndex={0}
					role="button"
					aria-label="help"
				>
					?
				</div>
			        {
					(this.state.userLocation ?
						(
						<div
							className="go-to-user-location-section"
							onClick={this.centerToUserLocation}
							tabIndex={0}
							role="button"
							onKeyUp={e => {
								if (e.keyCode === 13) {
									this.centerToUserLocation();
								}
							}}
						>
							Your location
							<div
								className="your-location"
								style={{ backgroundImage: `url(${myLocationIcon})` }}
							></div>
						</div>
						) : [])
				}

				<Modal
					aria-labelledby="help-modal"
					aria-describedby="help"
					open={this.state.isHelpSectionOpen}
					onClose={this.closeHelpSection}
				>
					<React.Fragment>
						<Touch>
							<HelpComponent
								closeHelpSection={this.closeHelpSection}
							>
								<CarouselComponent />
							</HelpComponent>
						</Touch>
						<Desktop>
							<Slide
								in={this.state.isHelpSectionOpen}
								direction="down"
								mountOnEnter
								unmountOnExit
							>
								<HelpComponent
									closeHelpSection={this.closeHelpSection}
								>
									<CarouselComponent />
								</HelpComponent>
							</Slide>
						</Desktop>
					</React.Fragment>
				</Modal>

				<Maps
					buildings={buildings}
					center={this.state.center}
					userLocation={this.state.userLocation}
					zoom={this.state.zoom}
					locationPermissionGranted={this.state.locationPermissionGranted}
					activePrinter={this.state.activePrinter}
					setActivePrinter={this.setActivePrinter}
					updateCenterFromMaps={this.updateCenterFromMaps}
				/>
			</div>
		);
	}
}

Home.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({ region: PropTypes.string })
	})
};

export default Home;
