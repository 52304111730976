const helpers = {
	getPrintersByType: printers => {
		const colorNotSupported = printers.filter(
			printer => !printer.IsColorSupported
		).length;
		const colorSupported = printers.length - colorNotSupported;
		let colorSupportedText, colorNotSupportedText;
		if (colorSupported === 1) colorSupportedText = colorSupported + " printer";
		else colorSupportedText = colorSupported + " printers";

		if (colorNotSupported === 1)
			colorNotSupportedText = colorNotSupported + " printer";
		else colorNotSupportedText = colorNotSupported + " printers";

		const printer = {
			colorNotSupported: colorNotSupportedText,
			colorSupported: colorSupportedText
		};
		return printer;
	}
};

export default helpers;
