import React from "react";
import colorPrinter from "common/images/color.svg";
import oval from "common/images/oval.svg";
import "./single-building.scss";
import PropTypes from "prop-types";

function SingleBuilding(props) {
	const {
		building,
		printerType,
		printerImg,
		locationPermissionGranted,
		sortNearestIsActive,
		showPrinterImage
	} = props;
	return (
		<React.Fragment>
			{showPrinterImage && (
				<div
					className="printer-img-section"
					style={{ backgroundImage: `url(${printerImg}?_cb=1726791190)` }}
				/>
			)}
			<div
				className={
					showPrinterImage
						? "printer-details-section"
						: "printer-details-section  full-width"
				}
			>
				<h1 className="building-name">
					<abbr title={building.site}>{building.site}</abbr>
				</h1>
				<div className="details-content address">
					{building.FormattedAddress}
				</div>
				<div className="details-content">
					<div className="available-printers">
						<div
							title="black and white printers"
							className="img"
							role="img"
							style={{ backgroundImage: `url(${oval})` }}
						/>
					</div>
					<div className="available-printers">
						{printerType.colorNotSupported}
					</div>
				</div>
				<div className="details-content">
					<div className="available-printers">
						<div
							aria-label="color printers"
							className="img"
							role="img"
							style={{
								backgroundImage: `url(${colorPrinter})`
							}}
						/>
					</div>
					<div className="available-printers">{printerType.colorSupported}</div>

					{locationPermissionGranted && sortNearestIsActive && (
						<div className="right-content blue">
							{(building.distance || 100 / 1609.344).toFixed(2) + " mi"}
						</div>
					)}
				</div>
			</div>
		</React.Fragment>
	);
}

SingleBuilding.propTypes = {
	building: PropTypes.shape({
		FormattedAddress: PropTypes.string.isRequired,
		ImageUrl: PropTypes.string.isRequired,
		Region: PropTypes.string.isRequired,
		building: PropTypes.string.isRequired,
		distance: PropTypes.number.isRequired,
		lat: PropTypes.number.isRequired,
		lng: PropTypes.number.isRequired,
		place_id: PropTypes.string.isRequired,
		printers: PropTypes.array.isRequired,
		site: PropTypes.string.isRequired,
		site_id: PropTypes.string.isRequired
	}),
	printerType: PropTypes.shape({
		colorNotSupported: PropTypes.string.isRequired,
		colorSupported: PropTypes.string.isRequired
	}),
	printerImg: PropTypes.string.isRequired,
	locationPermissionGranted: PropTypes.bool.isRequired,
	showPrinterImage: PropTypes.bool.isRequired
};

export default SingleBuilding;
