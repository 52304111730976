import React from "react";
import PropTypes from "prop-types";
import placeholderImg from "common/images/Printer-Icon.png";
import SwipeSection from "../swipe-section-mobile/swipe-section-mobile";
import helper from "common/helper.js";
import SingleBuilding from "./single-building/single-building";
import ListItem from "./list-item";
import { Touch } from "common/breakpoints";

function List(props) {
	const {
		printers,
		isPrinterListCollapsed,
		searching,
		currentRegion,
		toggleFilterDrawer,
		handleClick,
		filters,
		setActivePrinter,
		filterPrintersOnlineStatus,
		setFilterPrintersOnlineStatus,
		goToPrinterDetailedView,
		locationPermissionGranted,
		sortNearestIsActive
	} = props;
	return (
		<div
			className={
				isPrinterListCollapsed
					? searching
						? "list-wrap searching"
						: "list-wrap"
					: "list-wrap down"
			}
		>
			<Touch>
				<SwipeSection
					currentRegion={currentRegion}
					toggleFilterDrawer={toggleFilterDrawer}
					handleClick={handleClick}
					searching={searching}
					isPrinterListCollapsed={isPrinterListCollapsed}
					numberOfFilters={filters.length}
					setFilterPrintersOnlineStatus={setFilterPrintersOnlineStatus}
					filterPrintersOnlineStatus={filterPrintersOnlineStatus}
				/>
				)}
			</Touch>
			<main>
				<ul className="printers-list-section" aria-live="polite">
					{printers.length > 0 &&
						printers.map((data, index) => {
							let building = data;
							let printerImg = placeholderImg;
							let printerType = helper.getPrintersByType(building.printers);
							const center = {
								lat: building.lat,
								lng: building.lng
							};
							if (building.ImageUrl && building.ImageUrl !== "")
								printerImg = building.ImageUrl;
							return (
								<ListItem
									center={center}
									key={index}
									setActivePrinter={setActivePrinter}
									building={building}
									goToPrinterDetailedView={goToPrinterDetailedView}
								>
									<SingleBuilding
										building={building}
										printerType={printerType}
										printerImg={printerImg}
										locationPermissionGranted={locationPermissionGranted}
										showPrinterImage={true}
										sortNearestIsActive={sortNearestIsActive}
									/>
								</ListItem>
							);
						})}
					{printers.length === 0 && (
						<div className="no-printers-found">No printers found.</div>
					)}
				</ul>
			</main>
		</div>
	);
}

List.propTypes = {
	printers: PropTypes.arrayOf(
		PropTypes.shape({
			FormattedAddress: PropTypes.string.isRequired,
			ImageUrl: PropTypes.string.isRequired,
			Region: PropTypes.string.isRequired,
			building: PropTypes.string.isRequired,
			distance: PropTypes.number.isRequired,
			lat: PropTypes.number.isRequired,
			lng: PropTypes.number.isRequired,
			place_id: PropTypes.string.isRequired,
			printers: PropTypes.array.isRequired,
			site: PropTypes.string.isRequired,
			site_id: PropTypes.string.isRequired
		})
	),
	isPrinterListCollapsed: PropTypes.bool.isRequired,
	searching: PropTypes.bool.isRequired,
	currentRegion: PropTypes.shape({
		id: PropTypes.number.isRequired,
		title: PropTypes.string.isRequired,
		shortTitle: PropTypes.string.isRequired,
		url: PropTypes.string.isRequired,
		lat: PropTypes.number.isRequired,
		lng: PropTypes.number.isRequired
	}),
	toggleFilterDrawer: PropTypes.func.isRequired,
	handleClick: PropTypes.func.isRequired,
	filters: PropTypes.array.isRequired,
	setActivePrinter: PropTypes.func.isRequired,
	filterPrintersOnlineStatus: PropTypes.bool.isRequired,
	setFilterPrintersOnlineStatus: PropTypes.func.isRequired,
	goToPrinterDetailedView: PropTypes.func.isRequired,
	locationPermissionGranted: PropTypes.bool.isRequired
};

export default List;
