import React from "react";
import Drawer from "@material-ui/core/Drawer";
import MobileSearchInput from "components/search-input/mobile/mobile-search-input";
import Filter from "../filter/quick-filters";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = {
	modal: {
		borderTopLeftRadius: "5px",
		borderTopRightRadius: "5px"
	}
};
function SearchbarSection(props) {
	const {
		searching,
		toggleSearching,
		currentRegion,
		searchText,
		onChangeHandlerSearch,
		isFilterDrawerOpen,
		toggleFilterDrawer,
		setFilters,
		filters,
		classes,
		setSortNearestIsActive,
		sortNearestIsActive,
		locationPermissionGranted
	} = props;
	return (
		<div
			className={searching ? "search-bar-wrap searching" : "search-bar-wrap"}
		>
			<MobileSearchInput
				currentRegion={currentRegion}
				searching={searching}
				toggleSearching={toggleSearching}
				searchText={searchText}
				onChangeHandlerSearch={onChangeHandlerSearch}
			/>
			<Drawer
				open={isFilterDrawerOpen}
				anchor="bottom"
				classes={{ paperAnchorBottom: classes.modal }}
				onClose={() => toggleFilterDrawer()}
			>
				<Filter
					active={isFilterDrawerOpen}
					setFilters={setFilters}
					filters={filters}
					toggleFilterDrawer={toggleFilterDrawer}
					sortNearestIsActive={sortNearestIsActive}
					setSortNearestIsActive={setSortNearestIsActive}
					locationPermissionGranted={locationPermissionGranted}
				/>
			</Drawer>
		</div>
	);
}

SearchbarSection.propTypes = {
	searching: PropTypes.bool.isRequired,
	toggleSearching: PropTypes.func.isRequired,
	currentRegion: PropTypes.object.isRequired,
	searchText: PropTypes.string.isRequired,
	onChangeHandlerSearch: PropTypes.func.isRequired,
	isFilterDrawerOpen: PropTypes.bool.isRequired,
	toggleFilterDrawer: PropTypes.func.isRequired,
	setFilters: PropTypes.func.isRequired,
	filters: PropTypes.array.isRequired,
	classes: PropTypes.object
};

export default withStyles(styles)(SearchbarSection);
