import config from "config/config.js";

export default {
	zoom: 14,
	center: {
		lat: 40.7366686,
		lng: -73.98797569999999
	},
	key: config.mapsKey
};
