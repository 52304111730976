import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";

export default class PrinterDetailedViewLoader extends Component {
	render() {
		return (
			<div className="loader-section-printer-detailed">
				<div className="printers-loading-section-printer-detailed">
					<Skeleton count={1} height={270} />
					<Skeleton count={1} height={900} />
				</div>
			</div>
		);
	}
}
