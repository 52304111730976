import { useMediaQuery } from 'react-responsive'

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 991 })
  return isDesktop ? children : null
}

export const Touch = ({ children }) => {
  const isTouch = useMediaQuery({ maxWidth: 990 })
  return isTouch ? children : null
}

export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 576 })
  return isMobile ? children : null
}