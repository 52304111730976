import React from "react";
import { Router, Route } from "react-router-dom";
import Home from "home/home";
import PrinterDetailedView from "printer-detailed-view/printer-detailed-view-loader";
import history from "history/history";
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./material-ui-theme";

const Routes = () => (
	<Router history={history}>
		<MuiThemeProvider theme={theme}>
			<div>
				<Route exact path="/" component={Home} />
				<Route exact path="/:region" component={Home} />
				<Route exact path="/printer/:id" component={PrinterDetailedView} />
			</div>
		</MuiThemeProvider>
	</Router>
);
//all the routes of the project goes here

export default Routes;
