import React from "react";
import Loadable from "react-loadable";
import Loading from "./printer-detailed-loader";

const LoadableComponent = Loadable({
	loader: () => import("./printer-detailed-view"),
	loading: Loading
});

export default class App extends React.Component {
	render() {
		return <LoadableComponent {...this.props} />;
	}
}
