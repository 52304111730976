import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import Routes from "./routes.js";
import "./index.css";
import { createGenerateClassName, jssPreset } from "@material-ui/core/styles";
import JssProvider from "react-jss/lib/JssProvider";
import { create } from "jss";

const generateClassName = createGenerateClassName({
	dangerouslyUseGlobalCSS: true,
	productionPrefix: "c"
});
const jss = create({
	...jssPreset(),
	insertionPoint: "jss-insertion-point"
});
if (process.env.NODE_ENV !== "production") {
	ReactDOM.render(<Routes />, document.getElementById("root"));
} else {
	ReactDOM.render(
		<JssProvider jss={jss} generateClassName={generateClassName}>
			<Routes />
		</JssProvider>,
		document.getElementById("root")
	);
}

serviceWorker.unregister();
