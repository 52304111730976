import React from "react";
import printerIcon from "common/images/PrinterIcon.svg";
import selectedIcon from "common/images/Selected_Icon.svg";
import offlinePrinterIcon from "common/images/PrinterIcon-Gray.svg";
import myLocationIcon from "common/images/current-location.png";
import history from "history/history";
import PropTypes from "prop-types";
import TooltipDesktop from "./tooltip-desktop/tooltip-desktop";
import { Desktop, Touch } from "common/breakpoints";

const markerStyleSelected = icon => {
	return {
		backgroundImage: `url(${icon})`,
		backgroundRepeat: "no-repeat",
		width: "30px",
		height: "30px",
		backgroundSize: "contain",
		cursor: "pointer"
	};
};

const markerStyle = icon => {
	return {
		backgroundImage: `url(${icon})`,
		backgroundRepeat: "no-repeat",
		width: "40px",
		height: "40px",
		backgroundSize: "contain",
		cursor: "pointer",
		position: "relative"
	};
};

const Markers = props => {
	const {
		myLocation,
		activePrinter,
		setActivePrinter,
		text,
		site_id,
		printerType
	} = props;
	const goToDetails = (name, e) => {
		e.preventDefault();
		e.stopPropagation();
		history.push("/printer/" + name);
	};
	const center = {
		lat: props.lat,
		lng: props.lng
	};
	if (myLocation) {
		return (
			<div
				className="my-location"
				style={{
					backgroundImage: `url(${myLocationIcon})`
				}}
			/>
		);
	}
	let icon = props.onlineStatus ? printerIcon : offlinePrinterIcon;
	if (activePrinter) {
		icon = selectedIcon;
	}
	return (
		<React.Fragment>
			<Desktop>
				<div
					className="markers-wrap"
					style={!activePrinter ? markerStyleSelected(icon) : markerStyle(icon)}
					onMouseEnter={() => setActivePrinter(site_id)}
					onMouseLeave={() => setActivePrinter(null)}
					onClick={e => {
						e.stopPropagation();
						goToDetails(site_id, e);
					}}
				>
					{activePrinter && (
						<TooltipDesktop
							goToDetails={goToDetails}
							site_id={site_id}
							text={text}
							printerType={printerType}
						/>
					)}
				</div>
			</Desktop>
			<Touch>
				<div
					className="markers-wrap"
					style={!activePrinter ? markerStyleSelected(icon) : markerStyle(icon)}
					onClick={e => {
						e.stopPropagation();
						setActivePrinter(site_id, center);
					}}
				></div>
			</Touch>
		</React.Fragment>
	);
};

Markers.propTypes = {
	myLocation: PropTypes.bool,
	activePrinter: PropTypes.bool,
	setActivePrinter: PropTypes.func,
	site_id: PropTypes.string,
	text: PropTypes.string,
	printerType: PropTypes.shape({
		colorNotSupported: PropTypes.string.isRequired,
		colorSupported: PropTypes.string.isRequired
	}),
	lat: PropTypes.number.isRequired,
	lng: PropTypes.number.isRequired,
	onlineStatus: PropTypes.bool
};
export default Markers;
