import React from "react";
import PropTypes from "prop-types";
import "./help.scss";
import LeftIcon from "common/images/details-back.svg";

const HelpComponent = props => {
	const { closeHelpSection, customTitle, extraDialogCSS, extraHeaderCSS, extraContentCSS } = props;
	return (
		<div
			className={"help-modal " + (extraDialogCSS || "")}
			role="dialog"
			id="dialog1"
			aria-describedby="help-instructions"
			aria-labelledby="help-section-modal"
			aria-modal="true"
		>
			<div className={"mobile-header " + (extraHeaderCSS || "")}>
				<div
					className="back-icon-wrap"
					style={{ backgroundImage: `url(${LeftIcon})` }}
					tabIndex={0}
					onKeyUp={e => {
						if (e.keyCode === 13) {
							closeHelpSection(e);
						}
					}}
					onClick={closeHelpSection}
				/>
				<div className="header-title">{ customTitle || 'Help' }</div>
			</div>
			<div
				className="desktop-header"
				tabIndex={0}
				onKeyUp={e => {
					if (e.keyCode === 13) {
						closeHelpSection(e);
					}
				}}
				onClick={closeHelpSection}
			/>
			<div className={extraContentCSS || ""}>
				{props.children}
			</div>
		</div>
	);
};

HelpComponent.propTypes = {
	closeHelpSection: PropTypes.func.isRequired,
	customTitle: PropTypes.string,
	extraDialogCSS: PropTypes.string,
	extraHeaderCSS: PropTypes.string,
	extraContentCSS: PropTypes.string
};

export default HelpComponent;
