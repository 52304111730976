import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import DropdownComponent from "components/dropdown/dropdown";

const onlineStatus = [
	{ title: "Available Only", id: 0 },
	{ title: "All Printers", id: 1 }
];

const styles = () => ({
	root: {
		position: "absolute",
		display: "inline-block",
		left: "0px",
		top: "35px"
	}
});

function OnlineFilter(props) {
	const {
		filterPrintersOnlineStatus,
		setFilterPrintersOnlineStatus,
		classes
	} = props;
	const id = filterPrintersOnlineStatus ? 0 : 1;
	const name = id === 0 ? "Available Only" : "All Printers";
	const handleChange = value => {
		setFilterPrintersOnlineStatus(value === 0 ? true : false);
	};
	return (
		<div className={classes.root}>
			<DropdownComponent
				id="online-filter"
				handleChange={handleChange}
				activeName={name}
				activeId={id}
				list={onlineStatus}
				fontSize={{ title: "16px" }}
				listWidth={{ minWidth: "165px" }}
			/>
		</div>
	);
}

OnlineFilter.propTypes = {
	classes: PropTypes.object.isRequired,
	filterPrintersOnlineStatus: PropTypes.bool.isRequired,
	setFilterPrintersOnlineStatus: PropTypes.func.isRequired
};

export default withStyles(styles)(OnlineFilter);
